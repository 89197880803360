
import CommonMixin from '@/mixins/CommonMixin';
import FormMixin from '@/components/Common/Mixins/FormMixin';
import User from '@/models/User';
import { handleErrorUI } from '../util/error';
import { validateMandatoryFields, empty } from '@/util/validation';
import store from '@/store';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [CommonMixin, FormMixin],
  data() {
    return {
      data: {},
      changePassword: true,
      loading: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      imageURL2: '@/assets/img/icons/user_avatar.png',
      imageURL: null,
      imgCheck: true,
      photo: '',
      photoFormat: '',
      canUserChangePassword: true
    };
  },
  computed: {
    canSubmit() {
      let passwordValidated = true;
      if (this.changePassword) {
        passwordValidated =
          empty(this.oldPassword) ||
          empty(this.newPassword) ||
          empty(this.confirmPassword);
      }
      return (
        validateMandatoryFields(this.data, [
          'firstName',
          'lastName',
          'username',
          'email'
        ]) && passwordValidated
      );
    },
    accountForm: function() {
      return this.$refs.accountForm;
    }
  },
  async created() {
    try {
      this.data = await this.getProfile();
      const response = await this.fetchProfilePicture();
    } catch (err) {
      handleErrorUI(err);
    }
  },
  mounted() {
    this.canUserChangePassword = localStorage.getItem('islogin_sso') == null;
    this.fetchProfilePicture();
  },
  methods: {
    ...mapGetters('user', ['getProfile', 'getClientSession']),
    ...mapMutations('user', ['setProfile']),
    ...mapActions('user', ['updateProfileSSO', 'changePasswordSSO']),
    checkFullwidth(v) {
      // eslint-disable-next-line no-useless-escape
      return /^[ A-Za-z0-9_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]*$/.test(v);
    },
    checkSpecialSymbols(v) {
      // eslint-disable-next-line no-useless-escape
      return /^(?=.*[_@./~`#&+,:;=<>%$*|!^{}\[\]()?'"\\-]).{1,}$/.test(v);
    },
    cancelChanges() {
      if (this.changePassword) {
        this.changePassword = false;
        return;
      }
      this.$router.push({ name: 'home' }).catch(_ => {});
    },
    async saveChanges() {
      this.loading = true;

      try {
        await this.uploadFile(this.photo, this.photoFormat);
        const res = await this.updateProfileSSO({
          ...this.data
        });

        this.setProfile({
          profile: this.data
        });

        this.$q.notify({
          type: 'positive',
          position: 'top',
          message: `${this.$t('AuthPage.user_details_changed_successfully')}`
        });
       setTimeout(() => {
        window.location.reload();
       }, 1000);
        // }
      } catch (err) {
        handleErrorUI(err);
      } finally {
        this.loading = false;
      }
    },
    async submitPasswordChange() {
      if (this.newPassword !== this.confirmPassword) {
        throw new Error("Confirmation password doesn't match");
      }
      try {
        this.loading = true;
        await this.changePasswordSSO({
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
          newConfirmPassword: this.confirmPassword
        });
        this.$q.notify({
          position: 'top',
          message: `${this.$t('AuthPage.password_changed_successfully')}`,
          type: 'positive'
        });
        this.oldPassword = '';
        this.newPassword = '';
        this.confirmPassword = '';
        this.accountForm.reset();
      } catch (err) {
        if (err.message === 'Invalid old password') {
          this.$q.notify({
            type: 'negative',
            position: 'top',
            message: this.$t('AuthPage.invalid_old_password')
          });
        } else {
          handleErrorUI(err);
        }
      } finally {
        this.loading = false;
      }
    },
    handleFileUpload(event) {
      const files = event.target.files;
      for (const file of files) {
        const reader = new FileReader();
        reader.onloadend = () => {
          this.photo = reader.result;
          this.photoFormat = file.type.split('/')[1];

          // this.uploadFile(this.photo, this.photoFormat);
        };
        reader.readAsDataURL(file);
      }
    },
    async uploadFile(file, format) {
      // Remove the base64 prefix for easier server processing
      const base64Data = file.replace(`data:image/${format};base64,`, '');
      const response = await this.$repo.user.updateProfilePicture(base64Data, format);

      if (response) {
        await this.fetchProfilePicture();
      }
    },
    async fetchProfilePicture() {
      try {
        const response = await this.$repo.user.getProfilePicture();
        this.imageURL = response || this.$store.state.user.profile.photo || null;
        this.imgCheck = !this.imageURL; 
      } catch (err) {
        this.$q.notify({
          type: 'negative',
          position: 'top',
          message: 'Failed to retrieve the profile picture'
        });
      }
    }
  
  }
};
